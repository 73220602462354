<template>
  <footer class="mt-5">       
    <div>
      <img
        src="../assets/icons/freya-logotype.svg"
        alt="Freya logo"
      >
      <p id="freya-copy">
        FORM RENDERING EMBEDDING YEETING APPLICATION
      </p>
    </div>
  </footer>
</template>

<style lang="scss">

#freya-copy {
    font-family: "VIKING, ELDER Runes";
    margin-top: 1rem;
}

footer {
    text-align: center;
}
</style>