<template>
    <div class="collapse navbar-collapse" :id="id">
        <slot />        
    </div>
</template>

<script>
export default {
    props: {
        /**
        * The id object.
        */
        id: {
            type: String,
            required: true
        },
    },
}
</script>