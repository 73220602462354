import Router from '../../../router'

export default {
  /**
   * The authentication state of the user.
   * 
   * @param {Object} state
   *  
   * @returns {Boolean}
   */
  getAuthenticated(state) {
    return state.authenticated
  },

  /**
   * The access token that authenticates the user.
   *
   * @param {Object} state
   * 
   * @returns {string}
   */
  getAccessToken(state) {
    return state.accessToken
  },

  /**
   * The expiry time for the access token.
   * 
   * @param {Object} state
   * 
   * @returns {number}
   */
  getExpires(state) {
    return state.expires
  },

  /**
   * The authenticated user.
   * 
   * @param {Object} state 
   * 
   * @returns {Object}
   */
  getUser(state) {
    return state.user
  },

  /**
   * Determines if the authenticated user has access to a specified route.
   * 
   * @param {Object} state 
   * @param {Object} getters 
   * @param {string} routeName
   * 
   * @returns {Boolean}
   */
  hasRouteAccess: (state, getters) => (routeName) => {
    let route = Router.options.routes.find(route => route.name === routeName)

    let permissions = route.meta.permissions
    let requiresGuest = route.meta.guest
    let requiresAuth = route.meta.auth
    let isAuthenticated = getters.getAuthenticated

    if (requiresGuest && isAuthenticated) {
      return false
    }

    if (requiresGuest && ! isAuthenticated) {
      return true
    }

    if (requiresAuth && ! isAuthenticated) {
      return false
    }

    if (requiresAuth && isAuthenticated) {
      if (permissions.length === 0) {
        return true
      } else if (getters.hasAllPermissions(permissions)) {
        return true
      } else {
        return false
      }
    }

    return true
  },

  /**
   * Determines if the authenticated user has a given permission.
   * 
   * @param {Object} state 
   * @param {Object} getters 
   * @param {string} permissions
   * 
   * @returns {Boolean}
   */
  hasPermission: (state, getters) => (permissions) => {
    let user = getters.getUser

    if (! Object.prototype.hasOwnProperty.call(user, 'permissions')) {
      return false
    }

    let condition = (permission) => permission.name == permissions
    if (! user.permissions.some(condition)) {
      return false
    }

    return true
    
  },

  /**
   * Determines if the authenticated user has any of the given permissions.
   * 
   * @param {Object} state 
   * @param {Object} getters 
   * @param {Array|string} permissions
   * 
   * @returns {Boolean}
   */
  hasAnyPermission: (state, getters) => (permissions) => {
    if (permissions instanceof Array) {
      let results = []

      for (let i = 0; i < permissions.length; i++) {
        results.push(getters.hasPermission(permissions[i]))
      }

      return results.includes(true) ? true : false
    } else {
      return getters.hasPermission(permissions)
    }
  },

  /**
   * Determines if the authenticated user has all of the given permissions.
   * 
   * @param {Object} state 
   * @param {Object} getters 
   * @param {Array|string} permissions
   * 
   * @returns {Boolean}
   */
  hasAllPermissions: (state, getters) => (permissions) => {
    if (permissions instanceof Array) {
      let results = []

      for (let i = 0; i < permissions.length; i++) {
        results.push(getters.hasPermission(permissions[i]))
      }

      return results.includes(false) ? false : true
    } else {
      return false
    }
  },

  /**
   * Determines if the authenticated user has a given role.
   * 
   * @param {Object} state 
   * @param {Object} getters 
   * @param {string} roles
   * 
   * @returns {Boolean}
   */
   hasRole: (state, getters) => (roles) => {
    let user = getters.getUser

    if (! Object.prototype.hasOwnProperty.call(user, 'roles')) {
      return false
    }

    let condition = (role) => role.name == roles
    if (! user.roles.some(condition)) {
      return false
    }

    return true
  },  
}