import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import Config from './core/Config'
import EventBus from './core/EventBus'
import Event from './core/Dispatch'
import Axios from 'axios'
import Clipboard from 'v-clipboard'
import UUID from "vue-uuid"
import { tooltip } from './helpers/tooltip'
import { BootstrapIconsPlugin } from "bootstrap-icons-vue"

import '../src/assets/custom.scss'

let devEnvironments = [
  'development',
  'dev',
  'local'
]

if (devEnvironments.includes(process.env.VUE_APP_ENVIRONMENT)) {
  window.Config = Config
}

window.EventBus = new Event()
window.axios = Axios

const app = createApp({
  render: () => h(App)
})

app.use(router)
app.use(store)
app.use(Clipboard)
app.use(UUID)
app.use(EventBus)
app.use(BootstrapIconsPlugin)

app.directive('tooltip', tooltip)

// We need to expose all of the Freyastrap components globally so that they can be referenced dynamically.
let FreyastrapComponents = require.context('./components/Freyastrap', true, /.vue$/)

// Register each of the FS components globally.
FreyastrapComponents.keys().forEach(filename => {
  let module = FreyastrapComponents(filename)
  let moduleName = filename.replace(/(^.*[\\/])|(.vue)/ig, "")
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1-$2')
    .toLowerCase()
  app.component(moduleName, module.default || module)
})

app.mount('#app')
