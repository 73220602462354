import _ from "lodash"

/**
 * Creates a UUID.
 * 
 * @returns {string}
 */
export function uuid() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

export async function downloadFile(url, filename) {
  await axios({
    url: url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const href = URL.createObjectURL(response.data)

    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  })

}

export function paginateArray(array, pageSize) {
  if(!array || array.length < 1) { return [] }
 
  const pageCount = Math.ceil(array.length / pageSize);
  const paginatedArray = [];

  for (let i = 0; i < pageCount; i++) {
    const start = i * pageSize;
    const end = start + pageSize;
    paginatedArray.push(array.slice(start, end));
  }

  return paginatedArray;
}

export function sortByAttribute(arr, attribute) {
  const sortOrder = attribute.endsWith('-') ? "desc" : "asc"
  const path = attribute.replace(/-+$/, '')

  let sortedArray = _.sortBy(arr, path)

  if(sortOrder == 'desc'){
    sortedArray = sortedArray.reverse()
  }

  return sortedArray
}

export function stringToTableHeader(str) {
  // Capitalize the first letter of each word
  const formattedStr = str.replace(/_/g, ' ') // Replace underscores with spaces
                          .toLowerCase() // Convert to lowercase
                          .split(' ') // Split the string into words
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
                          .join(' '); // Join the words back into a string
  return formattedStr;
}

export function recursiveSearch(value, searchString) {
  if (_.isObject(value)) {
    // If the value is an object, recursively search its properties
    return _.some(value, (subValue) => recursiveSearch(subValue, searchString));
  } else {
    // If the value is a string, check if it contains the searchString
    return _.isString(value) && _.includes(value.toLowerCase(), searchString.toLowerCase());
  }
}

// Function to filter the array of objects for a specific string in complex object paths
export function filterObjectsForString(arr, searchString) {
  return _.filter(arr, (obj) => {
    // Check if any attribute in the object contains the searchString
    return _.some(obj, (value) => recursiveSearch(value, searchString));
  });
}