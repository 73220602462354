<template>
  <div class="toast-queue">
    <transition-group v-if="notifications" name="fade">
      <template
        v-for="(item, index) in notifications"
        :key="item.id"
      >
        <div 
          v-if="index === 0"
          class="v-toast"
          :class="getNotificationPosition(item)"
          @mouseover="pauseTimer()"
          @mouseleave="resumeTimer()"
        >
          <div class="v-toast__progress-bar">
            <progress-bar
              color="black"
              :percent="timer.progress"
            />
          </div>
          <div class="v-toast__content">
            <span class="content-title">
              <span class="text">{{ item.title }}</span>
            </span>
            <span class="content-body">
              <span
                class="text"
                v-html="item.body"
              />
            </span>
          </div>
          <div class="v-toast__actions">
            <div
              class="action__close"
              @click.prevent="close()"
            >
              <span class="text">close</span>
            </div>
            <div
              v-if="notifications.length > 1"
              class="action__next"
              @click.prevent="next(item.id)"
            >
              <span class="text">next</span>
            </div>
          </div>
        </div>
      </template>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProgressBar from './ProgressBar'

export default {
  components: {
    ProgressBar
  },

  data() {
    return {
      notifications: [],
      activeNotificationId: null,
      activeIntervalId: null,
      timer: {
        pollRate: 16,
        timeout: null,
        remaining: null,
        paused: false,
        progress: 100
      }
    }
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      stateNotifications: 'notifications/getNotifications'
    })
  },

  watch: {
    /**
     * Whenever the notifications have changed, update the timer so that it refreshes itself.
     */
    notifications(newVal, oldVal) {
      let oldNotification = oldVal.length > 0 ? oldVal[0] : null
      let newNotification = newVal.length > 0 ? newVal[0] : null

      let oldId = oldNotification ? oldNotification.id : null
      let newId = newNotification ? newNotification.id : null

      // Show the notification.
      if (newVal.length > 0 && oldId !== newId) {
        let vm = this

        // This timer should be adjusted to account for the animation on the element.
        setTimeout(function() {
          vm.startTimer(newVal[0])
        }, 400)
      }
    },
    
    /**
     * We need to trick the watchers into seeing changes via mutations.
     * This will bypass the issue where Vue will see the same value for 'new' and 'old'.
     * Note that the spread operator only works with shallow objects and not nested objects.
     */
    stateNotifications: {
      deep: true,
      handler(newVal) {
        this.notifications = [...newVal]
      }
      
    }
  },

  methods: {
    /** 
     * The actions mapped from Vuex.
     */
    ...mapActions({
      addNotification: 'notifications/add',
      removeNotification: 'notifications/remove',
      clearNotificationQueue: 'notifications/clear'
    }),

    /** 
     * The position of the notification on the screen.
     * 
     * @param {Object} notification
     * 
     * @returns {string}
     */
    getNotificationPosition(notification) {
      return notification.position ? notification.position.split('-').splice(0, 2) : []
    },

    /**
     * Closes the notification queue.
     * 
     * @returns {Void}
     */
    close() {
      this.clearTimer()
      this.clearNotificationQueue()
    },

    /**
     * Removes the currently displayed notification from the queue and displays the next.
     * 
     * @param {string} id
     * 
     * @returns {Void}
     */
    next(id) {
      this.clearTimer()
      this.removeNotification(id)
    },

    /**
     * Starts a countdown timer for the currently displayed notification.
     * 
     * @param {Object} notification
     * 
     * @returns {Void}
     */
    startTimer(notification) {
      if (notification.timeout !== null && notification.id != this.timer.notificationId) {
        this.activeNotificationId = notification.id
        this.timer.timeout = notification.timeout
        this.timer.remaining = notification.timeout

        // We only want to start the timer after initializing the info if the mouse is not
        // hovering over the popup. The paused boolean will tell us if the mouse is over the popup.
        if (this.timer.paused !== true) {
          this.updateTimer()
          this.activeIntervalId = setInterval(this.updateTimer, this.timer.pollRate)
        }
      }
    },

    /**
     * Pauses the notification's countdown timer.
     * 
     * @returns {Void}
     */
    pauseTimer() {
      this.timer.paused = true

      if (this.activeIntervalId) {
        clearInterval(this.activeIntervalId)
        this.timer.startTime = null
        this.activeIntervalId = null
      }
    },

    /**
     * Resumes the notification's countdown timer from where it was last paused.
     * 
     * @returns {Void}
     */
    resumeTimer() {
      // Only resume the timer if the previous one has been cleared.
      if (! this.activeIntervalId) {
        this.timer.paused = false
        this.activeIntervalId = setInterval(this.updateTimer, this.timer.pollRate)
      }
    },

    /**
     * Clears the notification's countdown timer.
     * 
     * @returns {Void}
     */
    clearTimer() {
      clearInterval(this.activeIntervalId)

      this.activeIntervalId = null
      this.activeNotificationId = null

      this.timer = {
        pollRate: 16,
        timeout: null,
        remaining: null,
        paused: false,
        progress: 100
      }
    },

    /**
     * Updates the notification's countdown timer.
     * 
     * @returns {Void}
     */
    updateTimer() {
      this.timer.remaining = this.timer.remaining - this.timer.pollRate
      this.timer.progress = (this.timer.remaining / this.timer.timeout) * 100

      if (this.timer.remaining <= 0) {
        if (this.activeNotificationId !== null) {
          this.next(this.activeNotificationId)
        } else {
          // This is needed to stop the setInterval call 
          // once the notification has been dismissed.
          this.clearTimer()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$color-white: #fff;
$border-color: #dee2e6;
$background-color: #F8F9FA;
$box-shadow--standard: 1px 1px 5px rgba(0, 0, 0, 0.3);
$break-mobile: 600px;
$break-sm: 800px;

.v-toast {
  opacity: 1;
  z-index: 9001;
  position: fixed;
  display: flex;
  background: $background-color;
  box-shadow: $box-shadow--standard;
  text-align: left;
  width: 100%;
  bottom: 0;
  transform: translateY(0%);

  // Force center when only top or bottom is specified.
  @media screen and (min-width: $break-mobile) {
    margin-top: 64px;
    margin-left: auto;
    margin-right: auto;
    width: initial;
    min-width: 300px;
    max-width: 500px;
    top: 10px;
    left: 0;
    right: 0;
    bottom: initial;
  }
}

.v-toast.top {
  @media screen and (min-width: $break-mobile) {
    top: 10px;
  }
}

.v-toast.bottom {
  @media screen and (min-width: $break-mobile) {
    top: initial;
    bottom: 10px;
  }
}

.v-toast.left {
  @media screen and (min-width: $break-mobile) {
    margin-left: initial;
    margin-right: initial;
    left: 10px;
    right: initial;
  }
}

.v-toast.right {
  @media screen and (min-width: $break-mobile) {
    margin-left: initial;
    margin-right: initial;
    left: initial;
    right: 20px;
  }
}

.v-toast__progress-bar {
  z-index: 9001;
  position: absolute;
  width: 100%;
  transform: translateY(-100%);
}

.v-toast__content {
  display: flex;
  flex-direction: column;
  padding: 10px 20px 10px 10px;
  flex-grow: 1;
  flex-shrink: 1;
}

.content-title {
  display: block;

  .text {
    font-weight: bold;
  }
}

.content-body {
  .text {
    font-size: 14px;
  }
}

.v-toast__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-left: 1px solid $border-color;
  width: 70px;
}

.action__close {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
  transition: background-color 0.5s ease;
  width: 100%;
  text-align: center;

  &:hover {
    cursor: pointer;
    background: lighten($background-color, 10%);
  }
}

.action__next {
  border-top: 1px solid $border-color;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
  transition: background-color 0.5s ease;
  width: 100%;
  text-align: center;

  &:hover {
    cursor: pointer;
    background: lighten($background-color, 10%);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s ease, transform 0.3s ease;
}
.fade-enter, .fade-leave-to {
  transform: translateY(100%);

  @media screen and (min-width: $break-mobile) {
    opacity: 0;
    transform: scale(0.25);
  }
}
</style>