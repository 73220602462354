<template>
    <div class="modal" :id="id" tabindex="-1">
      <div :class="`modal-dialog modal-${size}`">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <slot/>
          </div>
          <div class="modal-footer">
             <slot name="modal-footer"/>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
    props: {
        id: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'lg'
        },

        title: {
            type: String,
            default: ''
        },

    }
}
</script>