export default {
  /**
   * The initialization state of Axios.
   *
   * @param {Object} state
   * 
   * @returns {Boolean}
   */
  getInitialized(state) {
    return state.initialized
  },

  /**
   * The cancel tokens for each Axios request.
   *
   * @param {Object} state
   * 
   * @returns {Array}
   */
  getCancelTokens(state) {
    return state.cancelTokens
  },

  /**
   * The promise resolver for refreshing an access token.
   *
   * @param {Object} state
   * 
   * @returns {?Promise}
   */
  getRefreshTokenResolver(state) {
    return state.refreshTokenResolver
  },

  /**
   * The intended route.
   *
   * @param {Object} state
   * 
   * @returns {string}
   */
  getIntendedRoute(state) {
    return state.intendedRoute
  }
}