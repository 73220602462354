<template>
  <div class="v-progress-bar-wrapper">
    <span class="v-progress-bar">
      <span 
        class="v-progress-bar__fill" 
        :class="`v-progress-bar__fill--${color}`"
        :style="fillStyles"
      />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    'percent': {
      type: Number,
      default: 0
    },
    'color': {
      type: String,
      default: 'black'
    }
  },

  computed: {
    fillStyles: function () {
      return {
        transform: `translate3d(-${100 - this.percent}%, 0, 0)`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-progress-bar-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.v-progress-bar {
  position: relative;
  width: 100%;
  height: 2px;
  overflow: hidden;
}

.v-progress-bar__fill {
  position: absolute;
  z-index: 30;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  will-change: transform;
}

.v-progress-bar__fill--black {
  background-color: rgba(0, 0, 0, 0.7);
}
.v-progress-bar__fill--white {
  background-color: rgba(255, 255, 255, 1);
}

.v-progress-bar__fill--gray {
  background-color: rgba(0, 0, 0, 0.2);
}
</style>