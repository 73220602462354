/** 
 * Store all of the development environment configuration here.
 * 
 * Pure Javascript cannot pull environment variables the same way that
 * NodeJS can. This extends to Vue as well since most of the code is
 * written from the context of pure Javascript. To combat this,
 * environment variables can be assigned here while the application
 * is still in the context of NodeJS. They will then be pulled into
 * the global Config object when the application is compiled and built.
 * This allows environment variables to be set dynamically and used
 * throughout the application.
 * 
 * For example, the URL for an API defined in the `.env` file may 
 * be set in the following way:
 * 
 * export default {
 *   apiUrl: process.env.VUE_APP_API_URL
 * }
 * 
 * Throughout the application, this may be referenced as:
 * `Config.apiUrl`
 * 
 * Note that this only applies to a development environment.
 * Variables that are required for a production environment should
 * be set in the `entrypoint.sh` file at the root of this project.
 * 
 * All variables imported this way MUST be prefixed with `VUE_APP_`
 * in order to be picked up by the application.
*/

export default {
  apiUrl: process.env.VUE_APP_API_URL,
  formConfigUrl: process.env.VUE_APP_FORM_CONFIG_URL
}