export default {
  /**
   * The Ad Table represented as a JSON object.
   * 
   * @param {Object}
   */
  adTable: {},

  /**
   * The original Ad Table represented as a JSON object.
   * 
   * This is used to store the unmodified version of a Ad Table while editing.
   * 
   * @param {Object}
   */
  originalAdTable: {},

  /**
   * The default representation of an ad table object.
   * 
   * @param {Object}
   */
  defaultAdTable: {
    name: '',
    cta_text: '',
    program_load: [],
    entries: []
  }
}