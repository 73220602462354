export default {
  /**
   * Adds a notification to the notification queue.
   * 
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  add({commit}, payload) {
    commit('add', payload)
  },

  /**
   * Removes a notification from the notification queue.
   * 
   * @param {Object} context
   * @param {string} payload
   * 
   * @returns {Void}
   */
  remove({commit}, payload) {
    commit('remove', payload)
  },

  /**
   * Clears the notification queue.
   * 
   * @param {Object} context
   * 
   * @returns {Void}
   */
  clear({commit}) {
    commit('clear')
  }
}