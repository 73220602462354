import { find, forEach, isEmpty } from 'lodash'

export default {
  /**
   * The form object.
   *
   * @param {Object} state
   * 
   * @returns {Object}
   */
  getForm(state) {
    return state.form
  },

  /**
   * The original, unmodified form object.
   *
   * @param {Object} state
   * 
   * @returns {Object}
   */
  getOriginalForm(state) {
    return state.originalForm
  },

  /**
   * The default form configuration.
   * 
   * @param {Object} state
   * 
   * @returns {Object}
   */
  getDefaultForm(state) {
    return state.defaultForm
  },

  /**
   * The specified step and all of the associated fields.
   *
   * @param {Object} state
   * @param {number} stepIndex
   * 
   * @returns {Array}
   */
  getStep: (state) => (stepIndex) => {
    return state.variant.steps[stepIndex]
  },

  /**
   * The field object on the specified step.
   *
   * @param {Object} state
   * @param {number} stepIndex
   * @param {number} fieldIndex
   * 
   * @returns {Object}
   */
  getField: (state) => (stepIndex, fieldIndex) => {
    return state.variant.steps[stepIndex][fieldIndex]
  },

  /**
   * A list of all form variants.
   *
   * @param {Object} state
   * 
   * @returns {Object}
   */
   getFormVariants(state) {
    return state.variants;
  },

  /**
   * The specified form variant and all its associated fields.
   *
   * @param {Object} state
   * 
   * @returns {Object}
   */
  getFormVariant(state) {
    // return state.form.variants[variantIndex];
    return state.variant;
  },

  /**
   * The default form variants configuration.
   * 
   * @param {Object} state
   * 
   * @returns {Object}
   */
     getDefaultFormVariants(state) {
      return state.defaultVariants
    },

  /**
   * All field objects on the specified step.
   *
   * @param {Object} state
   * @param {number} stepIndex
   * @param {number} fieldIndex
   * 
   * @returns {Object}
   */
  getFields: (state) => (stepIndex) => {
    //if there is more than one step, return the fields before and at that step
    if(stepIndex > 0) {
      //slice the steps array to go from 0 (start) to current step (+1 being inclusive, where slice usually excludes end index)
      let steps = state.variant.steps.slice(0,  stepIndex+1)
      let arr = steps.map((step, index) => {
        return step.map((field, fi) => {
          //include the current step the field is on
          return {...field, step: index, fIndex: fi}
        })

      })
      return arr
    }
    return state.variant.steps[stepIndex].map((field, fi) => { 
      return {...field, step: stepIndex, fIndex: fi}
    })
  },

  /**
   * All program filters on the specified step.
   *
   * @param {Object} state
   * @param {Object} getters
   * @param {number} stepIndex
   * 
   * @returns {Array}
   */
  getProgramFilters: (state, getters) => (stepIndex) => {
    let programFilters = []
    let filters = find(getters.getFields(stepIndex), { id: 'programs-filter' }).meta.filters

    forEach(filters, (filter) => {
      if (!isEmpty(filter.label) && !isEmpty(filter.value)) {
        programFilters.push({ "text": filter.label, "value": filter.value })
      }
    })

    return programFilters
  },

  /**
   * The dynamic triggers for a specified form variant.
   *
   * @param {Object} state
   * @param {number} variantIndex
   * 
   * @returns {Object}
   */
  getConditionalTriggers: (state) => (variantIndex) => {
    if (state.conditionalTriggers.includes(variantIndex)) {
      return [...state.conditionalTriggers[variantIndex].visibility, ...state.conditionalTriggers[variantIndex].display]
    }
    return []
  },
  
  /**
   * Gets the current variant index.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  getCurrentVariantIndex(state) {
    return state.currentVariantIndex
  },  
  
}