import state from './state.js'
import getters from './getters.js'
import mutations from './mutations.js'
import actions from './actions.js'

/**
 * This module manages the Axios interceptors, along with other various Axios related items.
 * Interceptors are necessary to handle attaching the JWT to each request, as well as
 * refreshing the token should it expire periodically. If the token is expired, it will
 * automatically refresh and retry the original request behind the scenes without user input.
 */
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}