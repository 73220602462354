import baseFields from '../fields'
import { cloneDeep } from 'lodash'
import { required } from '../rules'

let fields = cloneDeep(baseFields)

let mandatoryFields = [
  'programs',
  'first-name',
  'last-name',
  'email',
  'phone',
  'country',
  'state',
  'sms-consent-checkbox'
]

let customFields = [
  // State / Province
  ...fields.filter(f => f.id === 'state').map(field => {
    return {
      ...field,
      meta: {
        ...field.meta,
        label: 'Select a State / Province',
        placeholder: 'Please select your State / Province'
      },
      admin: {
        ...field.admin,
        name: 'State / Province'
      }
    }
  }),

  // Zipcode / Postal Code
  ...fields.filter(f => f.id === 'zipcode').map(field => {
    return {
      ...field,
      meta: {
        ...field.meta,
        label: 'Postal Code',
        placeholder: 'Postal Code'
      },
      admin: {
        ...field.admin,
        name: 'Postal Code'
      }
    }
  }),
  // SMS Consent (Checkbox)
  ...fields.filter(f => f.id === 'sms-consent-checkbox').map(field => {
    return {
      ...field,
      meta: {
        ...field.meta,
        default: false,
        label: 'I authorise [PARTNER_NAME] and its representatives to contact me via SMS. Message and data rates may apply. [LINK_1]',
        links: [
          {
            text: 'Terms',
            url: 'https://policies.edusites.net/terms-int'
          }
        ]
      }
    }
  }),
   // First Name 
   ...fields.filter(f => f.id === 'first-name').map(field => {
    return {
      ...field,
      meta: {
        ...field.meta,
        classes:['half-width']
      },
    }
  }),
    // Last Name 
    ...fields.filter(f => f.id === 'last-name').map(field => {
      return {
        ...field,
        meta: {
          ...field.meta,
          classes:['half-width']
        },
      }
    })
]

let data = [
  ...cloneDeep(baseFields.map(field => {
    if (customFields.map(({id}) => id).includes(field.id)) {
      return { ...customFields.find(({id}) => id === field.id) }
    } else {
      return { ...field }
    }
  }))
]

data.forEach(field => {
  // Set all of the required fields.
  if (mandatoryFields.includes(field.id)) {
    field.admin.mandatory = true

    if (!field.admin.required_never) {
      field.admin.required = true
      field.meta.rules.push({ ...required })
      // don't let this be toggled off
      field.admin.required_always = true
    }
  }
})

export default data