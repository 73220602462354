<template>
    <nav>
        <ul class="pagination justify-content-center" :value="fieldValue">
            <li role="presentation" aria-hidden="true" class="page-item" :class="{ disabled: disabled || currentPage == 1}">
                <button role="menuitem" aria-label="Go to first page" aria-controls="my-table" aria-disabled="true" class="page-link" :disabled="disabled || currentPage == 1" @click.prevent="setFieldValue(1)">{{ firstText }}</button>
            </li>
            <li role="presentation" aria-hidden="true" class="page-item" :class="{ disabled: disabled || currentPage-1 == 0}">
                <button role="menuitem" aria-label="Go to previous page" aria-controls="my-table" aria-disabled="true" class="page-link" :disabled="disabled || currentPage-1 == 0" @click.prevent="setFieldValue(fieldValue-1)">{{ prevText }}</button>
            </li>
            <li v-for="item in computedItems" :key="`button-${item}`" class="page-item" :class="{ active: item == currentPage, disabled: disabled || item == '...'}" :disabled="disabled || item == '...'" @click.prevent="setFieldValue(item)">
                <button role="menuitemradio" type="button" aria-controls="my-table" :aria-label="`Go to page ${item}`" aria-checked="false" aria-posinset="2" aria-setsize="51" tabindex="-1" class="page-link">{{ item }}</button>
            </li>
            <li role="presentation" aria-hidden="true" class="page-item" :class="{ disabled: disabled || currentPage+1 > numberOfPages}">
                <button role="menuitem" aria-label="Go to next page" aria-controls="my-table" aria-disabled="true" class="page-link" :disabled="disabled || currentPage+1 > numberOfPages" @click.prevent="setFieldValue(fieldValue+1)">{{ nextText }}</button>
            </li>
            <li role="presentation" aria-hidden="true" class="page-item" :class="{ disabled: disabled || currentPage+1 > numberOfPages}">
                <button role="menuitem" aria-label="Go to last page" aria-controls="my-table" aria-disabled="true" class="page-link" :disabled="disabled || currentPage+1 > numberOfPages" @click.prevent="setFieldValue(numberOfPages)">{{ lastText }}</button>
            </li>
        </ul>
    </nav>
</template>

<script>

export default {
    props: {
        align: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        firstText: {
            type: String,
            default: "First"
        },
        lastText: {
            type: String,
            default: "Last"
        },
        prevText: {
            type: String,
            default: "Prev"
        },
        nextText: {
            type: String,
            default: "Next"
        },
        totalRows: {
            type: Number
        },
        perPage: {
            type: Number
        },
        limit: {
            type: Number,
            default: 5
        },
        hideEllipsis: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: Number,
            default: 0
        },
    
    },

    emits: ['update:modelValue'],

    data() {
        return {
            currentPage: 0
        }
    },

    computed: {
        /**
         * The computed number of pages - total rows/per page
         */
        numberOfPages() {
            return Math.ceil(this.totalRows / this.perPage)
        },

        /**
         * The items to display - before and after the current page hits the limit.
         */
        computedItems() {
            const pages = [];
            let startPage = 1;
            let endPage = this.totalRows;

            if (this.totalRows > this.limit) {
                const half = Math.floor(this.limit / 2);
                // set 'window' start and end
                startPage = Math.max(this.currentPage - half, 1);
                endPage = Math.min(this.currentPage + half, this.totalRows);

                // slide the window if needed
                if (endPage - startPage < this.limit - 1) {
                    if (this.currentPage <= half + 1) {
                        endPage = this.limit;
                    } else {
                        startPage = this.totalRows - this.limit + 1;
                    }
                }

                // start ellipses
                if (startPage > 2) {
                    pages.push('...');
                }
            }

            // accounting for the window position
            // keep pushing pages until you reach the end of the window or the max limit
            for (let i = startPage; i <= Math.min(endPage, this.numberOfPages); i++) {
                pages.push(i);
            }

            // end ellipses
            if (endPage < this.totalRows - 1) {
                pages.push('...');
            }

            return pages;
        },

        /**
         * The value that is passed from the parent component through `v-model`.
         * @param {String} val
         * 
         * @returns {String}
         */
        fieldValue: {
            get() { return this.modelValue },
            set(val) { this.$emit('update:modelValue', val) }
        },
    },

    methods: {
        setFieldValue(newVal){
            if(this.disabled){ return }
            
            if(typeof newVal === 'number'){
                this.fieldValue = newVal
            }
        }
    },

    watch: {
        modelValue(newVal) {
            this.currentPage = newVal
        }
    },

    created() {
        //if the v-model is greater than 0, set the local copy to it
        if(this.modelValue > 0) {
            this.currentPage = this.modelValue
        } else {
            this.currentPage = 1
        }
    }
}
</script>