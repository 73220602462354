
export default {
  id: 'state',
  component: 'fr-state',
  meta: {
    submitKey: 'state',
    default: null,
    label: 'Select a State',
    placeholder: 'Please select your State',
    optionsMode: null,
    options: [],
    rules: [],
    classes: [],
    visibility: [],
    display: []    
  },
  admin: {
    name: 'State',
    mandatory: false,
    required: false,
    required_always: false,
    required_never: false,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}