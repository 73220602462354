import { createRouter, createWebHistory } from 'vue-router'
import { store } from '../store'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  let requiresGuest = to.meta.guest
  let requiresAuth = to.meta.auth
  let isAuthenticated = store.getters['auth/getAuthenticated']
  let accessTokenExpiry = store.getters['auth/getExpires']
  let now = Date.now()
  
  // Initializing the Axios interceptors here will allow all requests to be
  // sent with the proper headers and handlers prior to the app being rendered.
  // We also want to cancel any pending requests when the page is changed to
  // save on the amount of requests being fired when they are no longer needed.
  await store.dispatch('axios/initInterceptors')
  await store.dispatch('axios/cancelPendingRequests')

  // Attempt to refresh the access token if it is expired.
  if (isAuthenticated && (now > Date.parse(accessTokenExpiry))) {
    try {
      await store.dispatch('auth/refreshAccessToken')
    } catch {
      store.dispatch('auth/logout')
    }
  }

  if (requiresGuest && isAuthenticated) {
    return next(from)
  }

  if (requiresGuest && ! isAuthenticated) {
    return next()
  }

  if (requiresAuth && ! isAuthenticated) {
    store.dispatch('axios/setIntendedRoute', to.path)

    return next('/login')
  }

  if (requiresAuth && isAuthenticated) {
    if (to.meta.permissions.length === 0) {
      return next()
    } else if (store.getters['auth/hasAllPermissions'](to.meta.permissions)) {
      return next()
    } else {
      next(from)

      return
    }
  }

  return next()
})

export default router