import { 
  postal_code
} from '../rules'

export default {
  id: 'zipcode',
  component: 'fr-zipcode',
  meta: {
    submitKey: 'zip',
    default: null,
    label: 'Zipcode',
    placeholder: 'Zipcode',
    rules: [
      { ...postal_code }
    ],
    classes: [],
    visibility: [],
    display: []    
  },
  admin: {
    name: 'Zipcode',
    mandatory: false,
    required: false,
    required_always: false,
    required_never: false,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}