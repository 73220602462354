<template>
    <div :id="id" class="mb-3">
        <slot v-if="hasLabelSlot" name="label" />
        <label v-else class="form-label" type="text" :for="labelFor">{{ label }}</label>
        <slot/>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        labelFor: {
            type: String,
            default: ''
        },
    },

    computed: {
        hasLabelSlot() {
            return !!this.$slots.label
        }
    }
}
</script>