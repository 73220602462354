import { uuid } from '../../../core/Functions'

export default {
  /**
   * Adds a notification to the notification queue.
   * 
   * @param {Object} state 
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  add(state, payload) {
    state.notifications.push({
      id: uuid(),
      title: payload.title ?? 'Information',
      body: payload.body ?? '',
      timeout: payload.timeout ?? 3000,
      position: payload.position ?? 'top-right'
    })
  },

  /**
   * Removes a notification from the notification queue.
   * 
   * @param {Object} state
   * @param {string} id
   * 
   * @returns {Void}
   */
  remove(state, id) {
    let notifications = state.notifications
    let index = notifications.findIndex(notification => notification.id === id)

    notifications.splice(index, 1)

    state.notifications = notifications
  },

  /**
   * Clears the notification queue.
   * 
   * @param {Object} state 
   * 
   * @returns {Void}
   */
  clear(state) {
    state.notifications = []
  }
}