export default {
  /**
   * The access token that authenticates the user.
   * 
   * @param {string}
   */
  accessToken: '',

  /**
   * The authentication state of the user.
   * 
   * @param {Boolean}
   */
  authenticated: false,

  /**
   * The expiry time for the access token.
   * 
   * @param {number}
   */
  expires: 0,

  /**
   * The authenticated user.
   * 
   * @param {Object}
   */
  user: {}
}