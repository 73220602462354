export default {
  id: 'form-qualifier-checkbox',
  component: 'fr-form-qualifier-checkbox',
  meta: {
    submitKey: 'formQualifier',
    default: false,
    label: "I confirm that I currently hold a bachelor's degree.",
    rules: [],
    classes: [],
    visibility: [],
    display: []    
  },
  admin: {
    name: 'Form Qualifier (Checkbox)',
    mandatory: false,
    required: false,
    required_always: false,
    required_never: false,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}