import {
  min,
  max
} from '../rules'

export default {
  id: 'first-name',
  component: 'fr-first-name',
  meta: {
    submitKey: 'firstName',
    default: null,
    label: 'First Name',
    placeholder: 'First Name',
    rules: [
      {
        ...min,
        parameters: {
          length: 2
        },
        message: 'This field must be at least 2 characters'
      },
      {
        ...max,
        parameters: {
          length: 40
        },
        message: 'This field must not exceed 40 characters'
      }
    ],
    classes: [],
    visibility: [],
    display: []    
  },
  admin: {
    name: 'First Name',
    mandatory: false,
    required: false,
    required_always: false,
    required_never: false,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}