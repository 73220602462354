export default {
  id: 'program-qualifier-dropdown',
  component: 'fr-program-qualifier-dropdown',
  meta: {
    submitKey: 'programQualifier',
    default: false,
    rules: [],
    classes: [],
    visibility: [],
    display: [],
    type: 'dropdown'    
  },
  admin: {
    name: 'Program Qualifier (Dropdown)',
    mandatory: false,
    required: false,
    required_always: false,
    required_never: false,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}