<template>
    <a :href="href"><slot /></a>
</template>

<script>
export default {
    props: {
        href: {
            type: String,
            default: '#'
        },
    },
}
</script>