export default {
  id: 'programs-filter',
  component: 'fr-programs-filter',
  meta: {
    submitKey: null,
    label: 'Select a Filter',
    placeholder: 'Select a Filter',
    filters: [
      {
        label: '',
        field: '',
        value: ''
      }
    ],
    rules: [],
    classes: [],
    visibility: [],
    display: []
  },
  admin: {
    name: 'Programs Filter',
    mandatory: false,
    required: false,
    required_always: false,
    required_never: false,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}