import {
  max
} from '../rules'

export default {
  id: 'anticipated-high-school-graduation-year',
  component: 'fr-anticipated-high-school-graduation-year',
  meta: {
    submitKey: 'anticipatedHighSchoolGraduationYear',
    default: null,
    label: 'High School Graduation Year',
    rules: [
      {
        ...max,
        parameters: {
          length: 18
        },
        message: 'This field must not exceed 18 characters'
      }
    ],
    classes: [],
    visibility: [],
    display: []    
  },
  admin: {
    name: 'Anticipated High School Graduation Year',
    mandatory: false,
    required: false,
    required_always: false,
    required_never: false,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}