import base from './base'
import domestic from './domestic'
import international from './international'
import zipcodeDomestic from './zipcode-domestic'
import postalCodeInternational from './postal-code-international'

export default {
  base,
  domestic,
  international,
  zipcodeDomestic,
  postalCodeInternational
}

export {
  base,
  domestic,
  international,
  zipcodeDomestic,
  postalCodeInternational
}