import {
  email,
  max
} from '../rules'

export default {
  id: 'parents-email',
  component: 'fr-parents-email',
  meta: {
    submitKey: 'parentGuardianEmail',
    default: null,
    label: "Parent's Email",
    rules: [
      { ...email },
      {
        ...max,
        parameters: {
          length: 80
        },
        message: 'This field must not exceed 80 characters'
      }
    ],
    classes: ['half-width'],
    visibility: [],
    display: []    
  },
  admin: {
    name: "Parent's Email",
    mandatory: false,
    required: false,
    required_always: false,
    required_never: false,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}