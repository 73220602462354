<template>
    <div :class="`spinner-${spinnerType}${spinnerSmall}`" :role="role">
        <span class="visually-hidden">{{ label }}</span>
    </div>
    <slot/>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: "Loading..."
        },
        role: {
            type: String,
            default: "status"
        },
        small: {
            type: Boolean,
            default: false
        },
        spinnerType: {
            type: String,
            default: "border"
        }
    },
    
    computed: {
        spinnerSmall(){
            return this.small ? ' spinner-border-sm' : ''
        }
    }
}
</script>