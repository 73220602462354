export default {
  /**
   * Sets the available Partners.
   * 
   * @param {Object} state 
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setPartners(state, payload) {
    state.partners = payload
  }
}