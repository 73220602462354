export default {
  /**
   * The list of Program Groups available.
   * 
   * @param {Object} state 
   * 
   * @returns {Array}
   */
  getProgramGroups(state) {
    return state.programGroups
  },

  /**
   * The list of Program Groups available for a specific partner.
   * 
   * @param {Object} state 
   * 
   * @returns {Array}
   */
  getProgramGroupsByPartner: (state) => (partnerId) => {
    let results = state.programGroups.filter(group => {
      return group.account_id == partnerId
    })

    return results
  }
}