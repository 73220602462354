export default {
  /**
   * Sets the Form config.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setForm(state, payload) {
    state.form = payload
  },

  /**
   * Sets the original, unmodified Form config.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setOriginalForm(state, payload) {
    state.originalForm = payload
  },

  /**
   * Sets the specified key on the form to the given value.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setFormValue(state, payload) {
    state.form[payload.key] = payload.value
  },

  /**
   * Sets the specified form step to the given value.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setStep(state, payload) {
    state.variant.steps[payload.index] = payload.value
  },

  /**
   * Sets the specified field on the step to the given value.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setField(state, payload) {
    state.variant.steps[payload.stepIndex][payload.fieldIndex] = payload.value
  },

  /**
   * Sets the specified key on the field to the given value.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setFieldValue(state, payload) {
    state.variant.steps[payload.stepIndex][payload.fieldIndex].meta[payload.key] = payload.value
  },

  /**
   * Sets the current form variant to the given value.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
   setFormVariant(state, payload) {
    state.variant = payload
  },

  /**
   * Sets the specified form variant to the given value.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
   setFormVariantValue(state, payload) {
    state.variant[payload.key] = payload.value
  },

  /**
   * Sets the form variants array to the given value.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
   setFormVariants(state, payload) {
     state.variants = payload
  },

  /**
   * Sets a specifed key on all of the form variants
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */  
  setValueAcrossAllFormVariants(state, payload) {
    state.variants.forEach((variant) => {
      variant[payload.key] = payload.value
    })

  },

  /**
   * Sets the a field value on the form variants' step array to the given value.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */  
  setFieldValueAcrossAllFormVariants(state, payload) {
    state.variants.forEach((variant) => {
      variant.steps.forEach((step, stepIndex) => {
          var foundIndex = variant.steps[stepIndex].findIndex(({id}) => id === payload.key)
          if (foundIndex !== -1) {
            let newData = Object.assign({}, payload.value) //assign the payload to new copy

            step[foundIndex].meta = newData //set the data to the new obj
            let dataKeys = []
            
            //go thru the metadata to look for arrays/object keys dynamically
            for(var key in payload.value) {
              //if the value is type object, it is not null, and the value at first index is also an obj (nested) - push the key
              if(typeof payload.value[key] === 'object' && payload.value[key] !== null && typeof payload.value[key][0] === 'object' && key !== 'rules') {
                dataKeys.push(key)
              } 
            }
			
            //if the datakeys array is not empty, run the code to update the array object
            if (dataKeys.length !== 0) {
              for(var dataKey of dataKeys) {
                let newOptions = [];

                for (let i = 0; i < payload.value[dataKey].length; i++) {
                  newOptions[i] = Object.assign({}, payload.value[dataKey][i]) //assign the options to a new copy

                  if(dataKey == 'visibility') {
                    EventBus.fire('update:visibility-value', {length: payload.value.visibility.length, field: payload.value.visibility[i].field, index: i, toggle: payload.value.visibility[i].toggle, match: payload.value.visibility[i].matchCondition})
                  }
                  if(dataKey == 'display') {
                    EventBus.fire('update:display-value', {length: payload.value.display.length, field: payload.value.display[i].field, index: i, displayName: payload.value.display[i].displayName, match: payload.value.display[i].matchCondition})
                  }
                }//inner for
              
                step[foundIndex].meta[dataKey] = newOptions
              } //outer for
            }//if length

            //set dynamic admin properties (kinda flubbin it)
            step[foundIndex].admin.dynamic = payload.admin.dynamic
            step[foundIndex].admin.dynamicDisplay = payload.admin.dynamicDisplay
            step[foundIndex].admin.dynamicLabel = payload.admin.dynamicLabel
          }          
       })
    })//end variants for each

  },

  /**
   * Pushes a value to the variants array
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */  
  addFormVariant(state, payload) {
    state.variants.push(payload)
  },

  /**
   * Sets the specified key on the admin field object to the given value.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setFieldAdminValue(state, payload) {
    state.variant.steps[payload.stepIndex][payload.fieldIndex].admin[payload.key] = payload.value
  },

  /**
   * Sets the triggers array of specified dynamic type to the given value.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setConditionalTriggerValue(state, payload) {
    //set array and remove empty strings
    state.conditionalTriggers[payload.variantIndex][payload.dynamicType] = payload.value.filter(Boolean)
  },
  
  /**
   * Adds to the triggers array.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  addConditionalTrigger(state, payload) {
    state.conditionalTriggers.push(payload)
  },  

  /**
   * Sets the current variant index.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setCurrentVariantIndex(state, payload) {
    state.currentVariantIndex = payload
  },
  
  /**
   * Updates the triggers array values to the value of the index passed in.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  updateConditionalTriggers(state, payload) {
    let savedTrigger = state.conditionalTriggers[payload.index]
    for(var i = 0; i < state.conditionalTriggers.length; i++) {
      state.conditionalTriggers[i] = savedTrigger
    }
  },

}