export default {
  /**
   * Fetches the available Partners from the Program Service API.
   * 
   * @param {Object} context
   * 
   * @returns {Promise}
   */
  async fetchProgramGroups({commit, dispatch}) {
    try {
      let response = await axios.get('/api/program-groups?limit=1500')

      let programGroups = response.data.data
      programGroups.sort((a, b) => String(a.name).toLowerCase() > String(b.name).toLowerCase() ? 1 : -1)

      commit('setProgramGroups', programGroups)
    } catch {
      dispatch('notifications/add', {
        title: 'Error',
        body: `There was a problem fetching the program groups from the server.`,
        timeout: 5000
      }, { root: true })
    }
  },

   /**
   * Fetches the available legend groups from the Program Service API.
   * 
   * @param {Object} context
   * 
   * @returns {Promise}
   */
    async fetchLegendProgramGroups({commit, dispatch}) {
      try {
        let response = await axios.get('/api/program-groups?account_id=9e57ab04-5a14-4f59-91f5-bfd027733b3e&limit=1500')
  
        let programGroups = response.data.data
        programGroups.sort((a, b) => String(a.name).toLowerCase() > String(b.name).toLowerCase() ? 1 : -1)
  
        commit('setProgramGroups', programGroups)
      } catch {
        dispatch('notifications/add', {
          title: 'Error',
          body: `There was a problem fetching the program groups from the server.`,
          timeout: 5000
        }, { root: true })
      }
    }

}