export default {
  id: 'sms-consent-checkbox',
  component: 'fr-sms-consent-checkbox',
  meta: {
    submitKey: 'okToText',
    default: true,
    label: null,
    links: [],
    rules: [],
    classes: [],
    visibility: [],
    display: []    
  },
  admin: {
    name: 'SMS Consent (Checkbox)',
    mandatory: false,
    required: false,
    required_always: false,
    required_never: true,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}