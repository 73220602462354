import Form from "../../../core/Form"
import { cloneDeep } from 'lodash'

export default {
  /**
   * Sets the Ad Table config with either the provided data, or the default data.
   *
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setAdTable({commit}, payload) {
    commit('setAdTable', payload)
  },

  /**
   * Sets the original, unmodified Ad Table config with the provided data.
   *
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setOriginalAdTable({commit}, payload) {
    commit('setOriginalAdTable', payload)
  },

  /**
   * Resets the ad table back to the default settings.
   * 
   * @param {Object} context
   * 
   * @returns {Void}
   */
  resetAdTable({commit, getters, dispatch}) {
    let adTable = new Form(
      cloneDeep(getters.getDefaultAdTable)
    )

    commit('setAdTable', adTable)
    dispatch('setOriginalAdTable', {})
  },

  /**
   * Sets a key on the ad table to the specified value.
   *
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setAdTableValue({commit}, payload) {
    commit('setAdTableValue', payload)
  }
}