<template>
    <button :id="id" :type="type" :class="{'btn-block': block}"><slot /></button>
</template>

<script>
export default {
    props: {
        name: {
            type: String
        },
        id: {
            type: String
        },
        type: {
            type: String,
            default: 'button'
        },
        block: {
            type: Boolean,
            default: false
        }
    },
}
</script>