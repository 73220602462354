export default {
  /**
   * The list of Partners available.
   * 
   * @param {Object} state 
   * 
   * @returns {Array}
   */
  getPartners(state) {
    return state.partners
  }
}