import Form from "../../../core/Form"
import variantTypes from '../../../data/forms/variant-types'
import { cloneDeep } from 'lodash'

export default {
  /**
   * Sets the Form config with either the provided data, or the default data.
   *
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setForm({commit}, payload) {
    commit('setForm', payload)
  },

  /**
   * Sets the original, unmodified Form config with the provided data.
   *
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setOriginalForm({commit}, payload) {
    commit('setOriginalForm', payload)
  },

  /**
   * Resets the from back to the default settings.
   * 
   * @param {Object} context
   * 
   * @returns {Void}
   */
  resetForm({commit, getters, dispatch}) {
    let form = new Form(
      cloneDeep(getters.getDefaultForm)
    )

    commit('setForm', form)
    dispatch('setOriginalForm', {})
  },

    /**
   * Resets the form variants back to the default settings.
   * 
   * @param {Object} context
   * 
   * @returns {Void}
   */
    resetFormVariants({commit, getters, dispatch}) {
      
      let defaultFormVariants = cloneDeep(getters.getDefaultFormVariants)
      commit('setFormVariants', defaultFormVariants)
      commit('setFormVariant', defaultFormVariants[0])
      commit('setFormVariantValue', {
        key: 'steps',
        value: [
          cloneDeep(
            // Include the basic mandatory fields by default.
            variantTypes.thedefault.domestic.filter(({admin}) => admin.mandatory)
          )
        ]
      })
      dispatch('addConditionalTrigger', {'visibility': [], 'display': []})
      dispatch('setOriginalForm', {})
    },


  /**
   * Sets a key on the form to the specified value.
   *
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setFormValue({commit}, payload) {
    commit('setFormValue', payload)
  },

  /**
   * Sets the specified form step to the given value.
   *
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setStep({commit}, payload) {
    commit('setStep', payload)
  },

  /**
   * Sets the specified field on the step to the given value.
   *
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setField({commit}, payload) {
    commit('setField', payload)
  },

  /**
   * Sets the specified key on the field to the given value.
   *
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */
    setFieldValue({commit}, payload) {
      commit('setFieldValue', payload)
    },
    
  /**
   * Sets the current form variant to the given value.
   *
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setFormVariant({commit}, payload) {
    commit('setFormVariant', payload)
  },

  /**
   * Sets the specified key on the form variant to the given value.
   *
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */
   setFormVariantValue({commit}, payload) {
    commit('setFormVariantValue', payload)
  },

  /**
   * Sets a field value across form variants array .
   *
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */
   setFieldValueAcrossAllFormVariants({commit, dispatch}, payload) {
    commit('setFieldValueAcrossAllFormVariants', payload)
    //check if the variant index is being passed in
    if('variantIndex' in payload){
      dispatch('updateConditionalTriggers', {index: payload.variantIndex})
    }
    
  },

  /**
   * Sets the field value variants array to the given value.
   *
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */
   setFormVariants({commit}, payload) {
    commit('setFormVariants', payload)
  },

  /**
   * Sets a specifed key on all of the form variants
   *
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */  
  setValueAcrossAllFormVariants({commit}, payload) {
    commit('setValueAcrossAllFormVariants', payload)
  },

  /**
   * Pushes a new variant onto the variants array.
   *
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */
 addFormVariant({commit, dispatch}, payload) {
  commit('addFormVariant', payload)
  //add conditional trigger when variant is created
  dispatch('addConditionalTrigger', {'visibility': [], 'display': []})
},  

  /**
   * Sets the specified key on the admin field object to the given value.
   *
   * @param {Object} context
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setFieldAdminValue({commit}, payload) {
    commit('setFieldAdminValue', payload)
  },

  /**
   * Sets the triggers array of specified dynamic type to the given value.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setConditionalTriggerValue({commit}, payload) {
    commit('setConditionalTriggerValue', payload)
  },
  
  /**
   * Adds to the triggers array.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  addConditionalTrigger({commit}, payload) {
    commit('addConditionalTrigger', payload)
  },  

  /**
   * Sets the current variant index.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setCurrentVariantIndex({commit}, payload) {
    commit('setCurrentVariantIndex', payload)
  },
  
  /**
   * Updates the conditional triggers array ala update across all variants
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  updateConditionalTriggers({commit}, payload) {
    commit('updateConditionalTriggers', payload)
  },
    
}