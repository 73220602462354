export default {
  /**
   * Sets the access token and expiry time.
   * 
   * @param {Object} state 
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setAccessToken(state, payload) {
    let expires

    if (Object.prototype.hasOwnProperty.call(payload, 'expiresIn')) {
      let now = new Date()
      expires = new Date(now.getTime() + payload.expiresIn * 1000)
    }

    state.accessToken = payload.accessToken
    state.expires = expires ?? payload.expires
  },

  /**
   * Sets the authenticated user.
   * 
   * @param {Object} state 
   * @param {Object} user
   * 
   * @returns {Void}
   */
  setUser(state, user) {
    state.user = user
    state.authenticated = true
  },

  /**
   * Clears the auth data and invalidates a user.
   * 
   * @param {Object} state 
   * 
   * @returns {Void}
   */
  clearAuthData(state) {
    state.accessToken = ''
    state.expires = 0
    state.user = {}
    state.authenticated = false
  }
}