import { uuid } from '../../../core/Functions'

export default {
  /**
   * Sets the initialization state of Axios.
   *
   * @param {Object} context
   * @param {Boolean} payload
   * 
   * @returns {Promise}
   */
  async setInitialized({commit}, payload) {
    commit('setInitialized', payload)
  },

  /**
   * Initializes the interceptors for Axios.
   * 
   * @param {Object} context
   * 
   * @returns {Promise}
   */
  async initInterceptors({dispatch, getters}) {
    if (! getters.getInitialized) {
      await dispatch('initRequestInterceptors')
      await dispatch('initResponseInterceptors')

      dispatch('setInitialized', true)
    }
  },

  /**
   * Initializes the request interceptors for Axios.
   * 
   * @param {Object} context
   * 
   * @returns {Promise}
   */
  async initRequestInterceptors({commit, rootGetters}) {
    axios.interceptors.request.use(config => {
      let source = axios.CancelToken.source()
      let accessToken = rootGetters['auth/getAccessToken']
      
      config.baseURL = Config.apiUrl
      config.cancelToken = source.token
      config.cancelTokenId = uuid()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      commit('addCancelToken', {
        id: config.cancelTokenId,
        url: config.url, // Makes debugging easier.
        source: source
      })

      return config
    }, error => Promise.reject(error))
  },

  /**
   * Initializes the response interceptors for Axios.
   * 
   * @param {Object} context
   * 
   * @returns {Promise}
   */
  async initResponseInterceptors({commit, dispatch, getters}) {
    axios.interceptors.response.use(
      response => {
        commit('removeCancelToken', response.config.cancelTokenId)

        return response
      },
      async error => {
        /** 
         * If a request has been cancelled, we want to refrain from resolving
         * or rejecting the existing promise. Instead, we want to return a
         * new promise with no intent to ever be resolved. This will allow
         * cancelled requests to bypass any catch blocks specified at the top level.
         * Garbage collection will take care of removing unresolved promises.
         */
        if (axios.isCancel(error)) {
          return new Promise(() => {})
        }

        let originalRequest = error.config
        let statusCode = error.response.status

        // Don't retry failed refresh attempts with a bad token.
        // Also ignore authentication attempts to the login route.
        if (
          statusCode === 401 && 
          (originalRequest.url.includes('api/token/refresh') || originalRequest.url.includes('api/login'))
        ) {
          return Promise.reject(error)
        }

        /**
         * If this was an unauthorized response and it is the first time
         * the interceptor has encountered it, we want to attempt to
         * fetch a new access token from the API. While fetching the
         * new access token, all requests will enter a `pending`
         * state until the access token has been either refreshed
         * successfully, or an error has been encountered. In the
         * event an error is encountered, all pending requests will
         * be cancelled and the user will be returned to the login page.
         */
        if (statusCode === 401 && ! originalRequest._retry) {
          originalRequest._retry = true

          if (! getters.getRefreshTokenResolver) {
            commit('setRefreshTokenResolver', (async () => {
              try {
                await dispatch('auth/refreshAccessToken', {}, { root: true })
              } catch (e) {
                await dispatch('cancelPendingRequests')
                dispatch('auth/logout', {}, { root: true })
                dispatch('notifications/add', {
                  body: `The session has expired.`,
                  timeout: 5000
                }, { root: true })

                throw Error(e)
              } finally {
                commit('setRefreshTokenResolver', null)
              }
            })())
          }

          try {
            await getters.getRefreshTokenResolver
          } catch {
            // When the refresh token fails, all of the pending requests will be cancelled.
            // In the event this happens, we want to return a new promise for all pending 
            // calls with no intent to resolve itself, similar to the previous scenario above.
            return new Promise(() => {})
          }

          return axios.request(originalRequest)
        }

        return Promise.reject(error)
      }
    )
  },

  /**
   * Cancels the pending Axios requests.
   *
   * @param {Object} context
   * 
   * @returns {Promise}
   */
  async cancelPendingRequests({getters, commit}) {
    getters.getCancelTokens.forEach(request => {
      if (request.source.cancel) {
        request.source.cancel()
      }
    })

    commit('clearCancelTokens')
  },

  /**
   * Sets the intended route.
   *
   * @param {Object} context
   * @param {string} payload
   * 
   * @returns {Void}
   */
  setIntendedRoute({commit}, payload) {
    commit('setIntendedRoute', payload)
  }

}