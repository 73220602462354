import { createStore } from 'vuex'
import axiosManager from './modules/axios'
import authModule from './modules/auth'
import formsModule from './modules/forms'
import adTablesModule from './modules/ad-tables'
import notificationsModule from './modules/notifications'
import partnersModule from './modules/partners'
import partnerAccountsModule from './modules/partner-accounts'
import programGroupsModule from './modules/program-groups'
import persistedState from "vuex-persistedstate"

export const store = createStore({
  modules: {
    axios: axiosManager,
    auth: authModule,
    forms: formsModule,
    notifications: notificationsModule,
    partners: partnersModule,
    partnerAccounts: partnerAccountsModule,
    programGroups: programGroupsModule,
    adTables: adTablesModule
  },

  plugins: [
    persistedState({
      paths: ['auth']
    })
  ]
})
