<template>
  <select
    :id="id"
    ref="input"
    v-model="selectedValue"
    :multiple="multiple"
    :required="required" 
    :class="['form-select', 'custom-select', {'is-invalid': state === false }, {'is-valid': state === true }]" 
    :aria-required="required"
    :aria-invalid="state === false"
    @change="handleChange"
  >
    <slot name="first" />
    <template v-for="(option, idx) in allOptions">
      <b-form-select-option-group
        v-if="option.optgroup"
        :key="`optgroup-${idx}`"
        :label="option.label"
        :options="option.options"
        :value="option.value"
        :text-field="option.text"
        :disabled="option.disabled"
      />

      <b-form-select-option 
        v-else
        :key="`option-${idx}`"
        :value="option.value"
        :disabled="option.disabled"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span
          v-if="!!option.html"
          v-html="option.html"
        />
        <template v-else>
          {{ option.text }}
        </template>
      </b-form-select-option>
    </template>
    <slot v-if="!componentMounted" />
  </select>
</template>

<script>
import BFormSelectOption from './BFormSelectOption.vue';
import BFormSelectOptionGroup from './BFormSelectOptionGroup.vue';


export default {
    components: { BFormSelectOption, BFormSelectOptionGroup },    
    props: {
        id: {
            type: String,
            default: null
        },
        multiple: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        options: {
            type: [Array, Object],
            default: () => []
        },
        modelValue: {
            type: [String, Boolean, Number, Array, Object],
            default: null
        },
        valueField: {
            type: String,
            default: 'value'
        },
        textField: {
            type: String,
            default: 'text'
        },
        htmlField: {
            type: String,
            default: 'html'
        },
        state: {
            type: Boolean,
            default: null
        }
    },
    
    emits: ['update:modelValue'],

    data() {
    return {
      selectedValue: null,
      refOptions: null,
      componentMounted: false  
  
    }
  },

    computed: {

        allOptions() {
            return [...this.normalizeOptions, ...this.refOpts()]
        },
 
        /**
         * Normalize all options/data coming in
         */
         normalizeOptions() {
            const normalizedOptions = [];
            if(this.options.length > 0) {
                this.options.forEach(option => {
                    if (option.options) {
                        normalizedOptions.push({
                            optgroup: true,
                            label: option.label,
                            options: option.options.map(suboption => ({
                            value: this.isObject(suboption[this.valueField]) ? JSON.stringify(suboption[this.valueField]) : suboption[this.valueField],
                            text: suboption[this.textField],
                            html: suboption[this.htmlField] ?? null, 
                            disabled: option.disabled
                            }))
                        });
                    } else {
                        normalizedOptions.push({
                            value: this.isObject(option[this.valueField]) ? JSON.stringify(option[this.valueField]) : option[this.valueField],
                            text: option[this.textField],
                            html: option[this.htmlField] ?? null,
                            disabled: option.disabled
                        });
                    }
                });    
            }
            return normalizedOptions;
        }        
    },

    watch: {
        /**
         * watch for modelvalue changes to update local state
         * @param {*} newVal 
         */
        modelValue(newVal) {
            this.selectedValue = newVal
        }
    },

    async mounted() {
        //set initial option
        //if model value is not falsy/null, use it
        if(!!this.modelValue) {
            this.selectedValue = this.modelValue
        }
        //else set initial option from options list 
        else {
            if(this.options.length > 0) {
                this.selectedValue = this.options[0][this.valueField]
            }
            if(this.options.length == 0 && this.modelValue != null) {
                this.selectedValue = this.modelValue
            }
        }
       

        this.refOptions = await this.$refs.input.options
        this.componentMounted = true
    },

    methods: {
        handleChange(event) {
            try {
                // leave multiple selections on the default behavior
                let val = null
                if(this.multiple){
                    //map selected option values
                    val = JSON.parse(Array.from(event.target.selectedOptions, option => option.value))
                } else {
                    // workaround for selecting options with no value
                    // the _value will have the actual value provided in the html
                    // instead of doing the default HTML fall back of
                    // setting the value to the label when no val is present
                    // this allows us to have null values
                    val = JSON.parse(event.target.selectedOptions[0]._value)
                }
                
                this.selectedValue = val
                this.$emit('update:modelValue', val)
            } catch {

                let val = null
                if(this.multiple) {
                    val = Array.from(event.target.selectedOptions, option => option.value)
                } else {
                    val = event.target.selectedOptions[0]._value
                }
                this.selectedValue = val // Update selectedValue when select value changes
                this.$emit('update:modelValue', val) 
            }
        },   

        get(obj, propName){
            var val = obj[propName];
            if(this.isUndefined(val) || val === null){
                return '';
            }
            return val;
        },
        isObject(x){
            return x != null && (typeof x === 'object' || typeof x === 'function');
        },
        isUndefined(x){
            return x === undefined
        },
        stripTags(text){
            var div = document.createElement("div");
            div.innerHTML = text;
            return div.textContent || div.innerText || "";
        },

        refOpts() {
            if(this.options.length === 0 && this.refOptions !== null) {
                let arr = Array.from(this.refOptions)
                return arr.map(option => ({
                    value: this.isObject(option._value) ? JSON.stringify(option._value) : option._value,
                    text: option.innerText,
                    html: null,
                    disabled: option.disabled
                }))
            }
            else {
              return []  
            }
            
        }

    }
}
</script>