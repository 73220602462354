/**
 * Make sure to update the allowFalse parameter when a field needs to send a false value.
 * This is especially important for fields such as the SMS Consent Checkbox.
 * The message may also be updated as well.
 */
export default {
  name: 'required',
  parameters: {
    allowFalse: false
  },
  message: 'This field is required'
}