<template>
    <optgroup :label="label">
      <slot name="first" />
      <b-form-select-option
        v-for="(option, index) in options"
        :key="index"
        :disabled="option.disabled"
        :value="option.value"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-if="!!option.html" v-html="option.html" />
        <template v-else>
          {{ option.text }}
        </template>
      </b-form-select-option>
    </optgroup>
  </template>

<script>
import BFormSelectOption from './BFormSelectOption.vue';

  export default {
    components: { BFormSelectOption },

    props: {
        label: {
            type: String
        },
        options: {
            type: Array,
        },
        value: {
            type: [String, Number, Boolean, Object, Array],
            default: null
        },
        valueField: {
            type: String,
            default: 'value'
        },
        textField: {
        type: String,
            default: 'text'
        },
        htmlField: {
            type: String,
            default: 'html'
        },
        disabled: {
            type: Boolean
        }
    }
  }
  </script>