import affiliate from './affiliate'
import abtest from './abtest'
import thedefault from './thedefault'


export default {
  affiliate,
  abtest,
  thedefault
}

export {
  affiliate,
  abtest,
  thedefault
}