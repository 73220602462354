export default {
  /**
   * The initialization state of Axios.
   *
   * @param {string}
   */
  initialized: false,

  /**
   * The cancel tokens for each Axios request.
   * 
   * @param {Array}
   */
  cancelTokens: [],

  /**
   * The promise resolver for refreshing an access token.
   * 
   * @param {?Promise}
   */
  refreshTokenResolver: null,

  /**
   * The intended route.
   * 
   * @param {string}
   */
  intendedRoute: ''
}