export default {
  /**
   * The ad table object.
   *
   * @param {Object} state
   * 
   * @returns {Object}
   */
  getAdTable(state) {
    return state.adTable
  },

  /**
   * The original, unmodified ad table object.
   *
   * @param {Object} state
   * 
   * @returns {Object}
   */
  getOriginalAdTable(state) {
    return state.originalAdTable
  },

  /**
   * The default ad table configuration.
   * 
   * @param {Object} state
   * 
   * @returns {Object}
   */
  getDefaultAdTable(state) {
    return state.defaultAdTable
  }
}