export default {
  /**
   * Fetches the available Partners from the Program Service API.
   * 
   * @param {Object} context
   * 
   * @returns {Promise}
   */
  async fetchPartnerAccounts({commit, dispatch}) {
    try {
      let response = await axios.get('/api/partners?limit=1500')      
      let accounts = response.data.data

      accounts.sort((a, b) => String(a.name).toLowerCase() > String(b.name).toLowerCase() ? 1 : -1)

      commit('setPartnerAccounts', accounts)
    } catch {
      dispatch('notifications/add', {
        title: 'Error',
        body: `There was a problem fetching the partner accounts from the server.`,
        timeout: 5000
      }, { root: true })
    }
  }
}