import Router from '../../../router'

export default {
  /**
   * Log a user into the system and set the authentication state.
   * 
   * @param {Object} context
   * @param {Object} response
   * 
   * @returns {Promise}
   */
  async login({commit, dispatch, rootGetters}, response) {
    commit('setAccessToken', {
      accessToken: response.access_token,
      expiresIn: response.expires_in
    })

    try {
      await dispatch('fetchUser')
    } catch (e) {
      dispatch('logout')
      dispatch('notifications/add', {
        title: 'Error',
        body: `There was a problem retrieving the user's information from the system. If the problem persists, please contact an administrator.`,
        timeout: 5000
      }, { root: true })

      throw Error(e)
    }
    
    if (rootGetters['axios/getIntendedRoute']) {
      try {
        await Router.push({ path: rootGetters['axios/getIntendedRoute'] })
      } catch {
        // If the router fails to push the user to the intended 
        // route, default to sending them to the `home` route.
        Router.push({ name: 'home' })
      }
    } else {
      Router.push({ name: 'home' })
    }

    dispatch('axios/setIntendedRoute', '', { root: true })
  },

  /**
   * Fetch the authenticated user's data from Freya's Admin API.
   * 
   * @param {Object} context
   * 
   * @returns {Promise}
   */
  async fetchUser({commit}) {
    try {
      let response = await axios.get(`/api/user`)

      commit('setUser', response.data.data)  
    } catch (e) {
      throw Error(e)
    }
  },

  /**
   * Refresh the authenticated user's access token.
   * 
   * @param {Object} context
   * 
   * @returns {Promise}
   */
  async refreshAccessToken({commit}) {
    try {
      let response = await axios.get(`/api/token/refresh`)

      commit('setAccessToken', {
        accessToken: response.data.data.access_token,
        expiresIn: response.data.data.expires_in
      })
    } catch (e) {
      throw Error(e)
    }
  },

  /**
   * Invalidate the authenticated user and return them to the login screen.
   * 
   * @param {Object} context
   * 
   * @returns {Promise}
   */
  async logout({commit}) {
    try {    
      commit('clearAuthData')
      Router.push({ name: 'auth.login' })
    } catch {
      // We don't care about routing errors when returning to the login page.
      // Wrapping this in a catch block will prevent `uncaught error` messages.
    }
  }
}