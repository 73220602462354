
export default {
  id: 'country',
  component: 'fr-country',
  meta: {
    submitKey: 'country',
    default: null,
    label: 'Select a Country',
    placeholder: 'Please select your Country',
    optionsMode: null,
    options: [],
    rules: [],
    classes: [],
    visibility: [],
    display: []    
  },
  admin: {
    name: 'Country',
    mandatory: false,
    required: false,
    required_always: false,
    required_never: false,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}