<template>
  <navbar v-if="isAuthenticated" />
  <div class="content">
    <transition
      name="fade-slide-up"
      mode="out-in"
    >
      <div v-show="true">
        <router-view />
      </div>
    </transition>

    <toast-queue />
    <freya-footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import ToastQueue from './components/ToastQueue'
import FreyaFooter from './components/Freya-Footer'
import { mapGetters } from 'vuex'

export default {
  components: { 
    Navbar, 
    ToastQueue,
    FreyaFooter
  },

  computed: {
    /** 
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      isAuthenticated: 'auth/getAuthenticated',
    })
  },
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  position: relative;
  padding: 20px 10px;
  flex-grow: 1;
}

.cursor-pointer {
  cursor: pointer;
}

// Fade
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-heading-enter-active, .fade-heading-leave-active {
  transition: opacity 0.3s ease;
}
.fade-heading-enter, .fade-heading-leave-to {
  opacity: 0;
}

// Fade & Slide Up
.fade-slide-up-enter-active, .fade-slide-up-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.fade-slide-up-enter, .fade-slide-up-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
.fade-slide-up-heading-enter-active, .fade-slide-up-heading-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.fade-slide-up-heading-enter, .fade-slide-up-heading-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}
</style>
