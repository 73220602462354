<template >
    <button 
        class="custom-select"
        @click="toggleDropdown()"
    >
        {{ selectionLabel }}
    </button>
    <div class="dropdown-menu" v-if="modelValue !== null && modelValue !== ''">
        <template 
            v-for="(option, idx) in allOptions"
            :key="`option-${idx}`"
        >
            <div class="form-check">
                <input
                    type="checkbox"
                    class="form-check-input-box"
                    :id="option.label"
                    :value="option.value"
                    @change="handleChange(option.value)"
                    :name="option.label"
                    :checked="modelValue.includes(option.value)"
                />
                <label 
                    :for="option.label"
                    class="form-check-label"
                >
                    {{ option.label }}
                </label>
            </div>
        </template>
    </div>
    <input class="hidden"
        :value="modelValue"
    />
</template>
  
<script>
export default {
    data() {
        return {
            selectedValue: [],
            rendered: false
        }
    },
    props: {
        allOptions: {
            type: [Array, Object],
            default: () => []
        },
        modelValue: {
            type: [String, Boolean, Number, Array, Object],
            default: null
        },
        defaultLabel: {
            type: String,
            default: 'Select'
        },
    },
    computed: {
        /**
         * Returns a string representing the current selection label.
         * If there are selected values, it joins them with commas.
         * Otherwise, it returns the default selection label.
         *
         * @return {string} The current selection label.
         */
        selectionLabel() {
            if (this.selectedValue.length > 0) {
                return this.selectedValue.join(', ')
            }
            return this.defaultLabel
        },
    },
    emits: ['update:modelValue'],
    watch: {
        modelValue(newVal) {
            /*
            * Sets the selected value to the modelValue passed in if it is not null/default
            * and also sets the rendered flag to true
            */
            if(newVal === null) {
                return
            }

            this.rendered = true

            if(typeof newVal !== 'string') {
                let tmpArray = [...newVal]
                this.selectedValue = tmpArray
            }
            
        },
        rendered(newVal) {
            /*
            *
            * This is a hacky way to close the dropdown when another element is clicked
            *
            * Initializes an event listener on the HTML element that listens for clicks.
            * When a click event occurs, it checks if the clicked element is not a dropdown menu or a custom select.
            * If the conditions are met, it calls the `toggleDropdown` method with the argument 'hide'.
            *
            * @return {void}
            */
            if(newVal) {
                document.querySelector("html").addEventListener("click", this.clickOutside)
            }
        }
    },
    methods: {
        /**
         * Toggles the dropdown menu visibility based on the provided option.
         *
         * @param {string} option - The option to toggle the dropdown menu. If 'hide', the dropdown menu will be hidden. Otherwise, the dropdown menu will be toggled.
         * @return {void} This function does not return a value.
         */
        toggleDropdown(option) {
            if(option === 'hide') {
                document.querySelector('.dropdown-menu').classList.remove('show')
                return
            }
            document.querySelector('.dropdown-menu').classList.toggle('show')
        },

        /**
         * Handles the change event for the select multi component.
         * Adds or removes the selected option from the array.
         *
         * @param {any} val - The value that was selected.
         * @return {void}
         */
        handleChange(val) {
            if(this.selectedValue.includes(val)) {
                this.selectedValue.splice(this.selectedValue.indexOf(val), 1)
            }
            else {
                this.selectedValue.push(val)
            }
            this.selectedValue.sort()
            this.$emit('update:modelValue', this.selectedValue)
        },
        /**
         * Handles the click event outside of the dropdown menu and custom select.
         *
         * @param {Event} e - The click event.
         * @return {void} This function does not return a value.
         */
        clickOutside(e) {
            let path = e.composedPath()
            let classNames = path.map(p => p.className)
            if(!classNames.includes("dropdown-menu show") 
            && !classNames.includes("custom-select")) {
                this.toggleDropdown('hide')
            }
        }
    },
    mounted() {
        if(!!this.modelValue) {
            this.selectedValue = this.modelValue
        }
        if(!!this.$parent.$parent.id && this.$parent.$parent.id.includes('modal')) {
            return
        }
        else {
            document.querySelector("html").addEventListener("click", this.clickOutside)
        }
    },
    unmounted() {
        /**
         * Removes the event listener for the click event on the HTML element.
         *
         * This function is called when the component is unmounted and is used to clean up the event listener that was added in the `mounted` lifecycle hook.
         *
         * @return {void} This function does not return anything.
         */
        document.querySelector("html").removeEventListener("click", this.clickOutside)
    }
}
</script>

<style lang="scss" scoped>


.custom-select {
  width: 100%;
  text-align: left;
}

.dropdown {
  position: relative;
  display: inline-block;
}
.show {
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #f1f1f1;
  min-width: 120px;
  overflow-y: auto;
  max-height: 100px;
  border: 1px solid #ddd;
  z-index: 1;
}

.dropdown-menu{
  width: 100%;
  text-align: left;
}

.hidden {
  display: none;
}

.multi-select-checkbox-label {
  width: 100%;
}

.form-check {
    position: relative;
    display: flex;
    padding-left: 0.25rem;
  &:hover {
    background-color: #ceb6ff;
  }
}

.form-check-label {
    display: inline-flex;
    align-items: center;
    margin-right: 0.25rem;
    width: 100%;
}

.form-check-input-box {
    background-color: #f1f1f1;
}

</style>