export default {
  /**
   * Sets the Ad Table config.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setAdTable(state, payload) {
    state.adTable = payload
  },

  /**
   * Sets the original, unmodified Ad Table config.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setOriginalAdTable(state, payload) {
    state.originalAdTable = payload
  },

  /**
   * Sets the specified key on the form to the given value.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setAdTableValue(state, payload) {
    state.adTable[payload.key] = payload.value
  }
}