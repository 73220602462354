<template>
  <label
    v-if="label"
    :for="id"
  >{{ label }}</label>
  <input 
    :id="id" 
    :type="type" 
    :placeholder="placeholder"
    :disabled="disabled"
    :required="required" 
    :value="modelValue" 
    :class="['form-control', {'is-invalid': state === false }, {'is-valid': state === true }]" 
    @input="handleInput"
    @change="handleChange"
  >
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: ''
        },
        modelValue: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean
        },
        required: {
            type: Boolean,
            default: false
        },
        state: {
            type: Boolean,
            default: null
        }
    },
    emits: ['change', 'input', 'update:modelValue'],

    methods: {
        handleInput(event) {
            this.$emit('update:modelValue', event.target.value)
            this.$emit('input', event.target.value)
        },
        handleChange(event) {
            this.$emit('change', event.target.value)
        }
  }
}
</script>