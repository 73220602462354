import {
  max
} from '../rules'

export default {
  id: 'street',
  component: 'fr-street',
  meta: {
    submitKey: 'streetAddress',
    default: null,
    label: 'Street Address',
    rules: [
      {
        ...max,
        parameters: {
          length: 255
        },
        message: 'This field must not exceed 255 characters'
      }
    ],
    classes: [],
    visibility: [],
    display: []    
  },
  admin: {
    name: 'Street',
    mandatory: false,
    required: false,
    required_always: false,
    required_never: false,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}