<template>
    <div class="form-control">
        <ul class="list-unstyled b-form-tag align-items-center d-inline-flex">
            <li v-for="(tag,idx) in fieldValue" :key="`tag-${tag}`" class="badge align-items-center d-inline-flex" :class="[{ 'rounded-pill': tagPills}, `bg-${tagVariant}`]">
            <span>{{ tag }}</span>
            <button type="button" class="btn-close btn-close-white" aria-label="Delete tag" @click="removeTag(idx)"></button>
            </li>
            <li>
                <div class="d-flex">
                    <input :id="id" class="w-100" type="text" :placeholder="placeholder" @keyup="handleInput($event.key, tagInput)" v-model="tagInput"/>
                    <button type="button" class="btn button--outline-primary text-secondary" v-if="!!tagInput && !isDuplicate" @click="addTag(tagInput)">Add</button>
                </div>
            </li>
        </ul>
        <div>
           <small v-if="isDuplicate">{{ duplicateTagText }}</small>  
        </div>         
    </div>
    
    
</template>

<script>
export default {
    props: {
        name: {
            type: String
        },
        id: {
            type: String
        },
        placeholder: {
            type: String,
            default: ''
        },
        duplicateTagText: {
            type: String,
            default: 'Class already exists'
        },       
        separator: {
            type: [String, Array],
            default: 'enter'
        },
        removeOnDelete: {
            type: Boolean
        },        
        modelValue: {
            type: Array,
            default: () => []
        },
        tagVariant: {
            type: String,
            default: 'primary'
        },
        tagPills: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],


    data() {
        return {
            tagInput: ''
        }
    },

    computed: {

    /**
     * The value that is passed from the parent component through `v-model`.
     * @param {String} val
     * 
     * @returns {String}
     */
     fieldValue: {
      get() { return this.modelValue },
      set(val) { this.$emit('update:modelValue', val) }
    },

    /**
     * Whether the tag (trimmed) already exists in the array
     * @param {String} val
     * 
     * @returns {Boolean}
     */
    isDuplicate() {
        return this.fieldValue.includes(this.tagInput.trim())
    }


    },

    methods: {
        removeTag(index) {
            this.fieldValue.splice(index, 1)
        },

        addTag(value) {
            this.fieldValue.push(value)
            //clear input
            this.tagInput = ''

        },
        
        handleInput(key, input) {
            //remove on delete if its set to true, if input is empty, and if keys are pressed
            if(this.removeOnDelete && this.input == '' && (key.toLowerCase() == "delete" || key.toLowerCase() == "backspace")) {
                this.removeTag(-1)
            }
            //if the separator is the enter key, the key pressed is enter, and the tag is not a duplicate, strip the \n char and run the add tag
            if(this.separator == "enter") {
                if(key == "Enter" && !this.isDuplicate) {
                    this.addTag(input.trim()) 
                }
            }
            else {
                let separatorArray = []
                //if separator is a string
                if (typeof this.separator == "string") {
                   separatorArray = this.separator.split("") //break string of separator characters into array 
                }
                else {
                    separatorArray = this.separator
                }
                
                //if key is in the separator array, add the tag input (minus the separator key)
                if(separatorArray.includes(key)) {
                    this.tagInput = this.tagInput.replace(key, '')
                    if(!this.isDuplicate) {
                        this.addTag(input.replace(key, ''))
                    }
                    
                }
   
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.btn-close {
    width: 2px;
    height: 2px;
    margin-left: 0.25rem;
}
.b-form-tag {
    font-weight: 400;
    line-height: 1.5;
    
}

ul > li {
    margin-right: 0.25rem;
}

li > div {
    width: 500px;
}
</style>