export default {
  id: 'programs',
  component: 'fr-programs',
  meta: {
    submitKey: 'program',
    default: null,
    label: 'Select a Program',
    placeholder: 'Select a Program',
    groups: [],
    rules: [],
    classes: [],
    visibility: [],
    display: []    
  },
  admin: {
    name: 'Programs',
    mandatory: false,
    required: false,
    required_always: false,
    required_never: false,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}