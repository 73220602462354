<template>
    <div class="file-input-container">
        <div class="drop-area" @dragover="handleDragOn" @drop="handleDrop" @dragleave="handleDragLeave">
            <input :id="id" type="file" class="custom-file-input" :placeholder="placeholderText" :accept="accept"/>
            <label data-browse="Browse" class="custom-file-label">
                <span id="input-text" class="d-block">{{ selectedFiles.length ? selectedFiles.length === 1 ? selectedFiles[0].name : `${selectedFiles.length} files selected` : placeholder }}</span>
            </label>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        id: {
            type: String
        },

        accept: {
            type: String,
            default: null
        },

        multiple: {
            type: Boolean,
            default: false
        },

        placeholder: {
            type: String,
            default: ''
        },

        dropPlaceholder: {
            type: String,
            default: ''
        },

        label: {
            type: String,
            default: 'Choose file(s)'
        },

        modelValue: {
            type: [FileList, Array],
            default: () => []
        },

    },

    emits: ['update:modelValue'],

    data() {
        return {
            selectedFiles: [],
        }
    },
    methods: {
        handleFileInputChange(event) {
            const files = event.target.files;
            this.selectedFiles = Array.from(files);

            if(this.multiple){
                this.$emit('update:modelValue', this.selectedFiles);
            } else {
                this.$emit('update:modelValue', this.selectedFiles[0]);
            }            
        },

        handleDrop(event) {
            event.preventDefault();
            const files = event.dataTransfer.files;
            this.selectedFiles = Array.from(files);

            if (this.multiple) {
                this.$emit('update:modelValue', this.selectedFiles);
            } else {
                this.$emit('update:modelValue', this.selectedFiles[0]);
            }

            this.handleDragLeave();
        },

        handleDragLeave() {
            document.getElementById("input-text").innerHTML = this.placeholder
        },
        
        handleDragOn(event) {
            event.preventDefault()
            //label to appear on the input - will change if a file is being dragged over it
            document.getElementById("input-text").innerHTML = this.dropPlaceholder
        },

        handleDragOff(event) {
            event.preventDefault()
            //label will change back to the default if the draggable leaves the area
            document.getElementById("input-text").innerHTML = this.placeholder
        }

    }
};
</script>

<style scoped>

    input {
        z-index: -5;
    }

    .custom-file {
        display: inline-block;
        margin-bottom: 0;
    }

    .custom-file,
    .custom-file-input {
        position: relative;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
    }

    .custom-file-input {
        z-index: 2;
        margin: 0;
        overflow: hidden;
        opacity: 0;
    }

    .custom-file-label {
        white-space: nowrap;
        overflow-x: hidden;
        left: 0;
        z-index: 1;
        height: calc(1.5em + 0.75rem + 2px);
        overflow: hidden;
        font-weight: 400;
        background-color: #fff;
        border: 1px solid #919191;
        border-radius: 0.25rem;
    }

    .custom-file-label,
    .custom-file-label:after {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.375rem 0.75rem;
        line-height: 1.5;
        color: #495057;
    }

    .custom-file-label:after {
        bottom: 0;
        z-index: 3;
        display: block;
        height: calc(1.5em + 0.75rem);
        content: attr(data-browse);
        background-color: #c9c9c9;
        border-left: inherit;
        border-radius: 0 0.25rem 0.25rem 0;
    }
.file-input-container {
    position: relative;
    overflow: hidden;
}
.drop-area.dragover {
    background-color: #f0f0f0;
}
</style>
