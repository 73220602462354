import Errors from "./Errors"
class Form
{
  /**
   * Create a new Form instance.
   * 
   * @memberof Form
   *
   * @param {object} data
   */
  constructor(data) {
    
    this.originalData = data

    for (let field in data) {
      this[field] = data[field]
    }

    this.errors = new Errors()
    this.submitting = false
  }

  /**
   * Fetch all relevant data for the form.
   * 
   * @memberof Form
   * 
   * @returns {object}
   */
  data() {
    let data = {}

    for (let property in this.originalData) {
      data[property] = this[property]
    }

    return data
  }

  /**
   * Reset the form fields.
   * 
   * @memberof Form
   */
  reset() {
    for (let field in this.originalData) {
      this[field] = this.originalData[field]
    }

    this.errors.clear()
    this.submitting = false
  }

  /**
   * Send a GET request to the given URL.
   * 
   * @memberof Form
   * 
   * @param {string} url
   * 
   * @returns {object}
   */
  get(url, config) {
    return this.submit('get', url, config)
  }

  /**
   * Send a POST request to the given URL.
   * 
   * @memberof Form
   * 
   * @param {string} url
   * 
   * @returns {object}
   */
  post(url, config) {
    return this.submit('post', url, config)
  }

  /**
   * Send a PUT request to the given URL.
   * 
   * @memberof Form
   * 
   * @param {string} url
   * 
   * @returns {object}
   */
  put(url, config) {
    return this.submit('put', url, config)
  }

  /**
   * Send a PATCH request to the given URL.
   * 
   * @memberof Form
   * 
   * @param {string} url
   * 
   * @returns {object}
   */
  patch(url, config) {
    return this.submit('patch', url, config)
  }

  /**
   * Send a DELETE request to the given URL.
   * 
   * @memberof Form
   * 
   * @param {string} url
   * 
   * @returns {object}
   */
  delete(url, config) {
    return this.submit('delete', url, config)
  }

  /**
   * Submit the form.
   * 
   * @memberof Form
   *
   * @param {string} requestType
   * @param {string} url
   * @param {object} config
   * 
   * @returns {Promise<object>}
   */
  submit(requestType, url, config) {
    this.errors.clear()
    this.submitting = true

    return new Promise((resolve, reject) => {
      axios[requestType](url, this.data(), config)
        .then(response => {
          this.onSuccess(response)

          resolve(response)
        })
        .catch(error => {
          this.onFail(error.response)

          reject(error.response)
        })
    })
  }

  /**
   * Handle a successful form submission.
   * 
   * @memberof Form
   *
   * @param {object} data
   */
  onSuccess() {
    this.submitting = false
  }

  /**
   * Handle a failed form submission.
   * 
   * @memberof Form
   *
   * @param {object} response
   */
  onFail(response) {
    this.errors.setMessage(response.data.message)
    if (Object.prototype.hasOwnProperty.call(response.data, 'errors')) {
      this.errors.record(response.data.errors)  
    }

    this.submitting = false
  }
}

export default Form