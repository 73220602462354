import EventBus from './EventBus'
class Event {
  /**
   * Create a new Event instance.
   * 
   * @memberof Event
   */
  constructor() {
    this.eb = EventBus
  }

  /**
   * Fires an event on the instance.
   * 
   * @memberof Event
   * 
   * @param {string} event 
   * @param {object} data 
   */
  fire(event, data = null) {
    this.eb.$emit(event, data)
  }

  /**
   * Listens for an event fired on the instance.
   * 
   * @memberof Event
   * 
   * @param {string} event 
   * @param {function} callback 
   */
  listen(event, callback) {
    this.eb.$on(event, callback)
  }
  
  /**
   * Destroys the event fired on the instance.
   * 
   * @memberof Event
   * 
   * @param {string} event
   */
  destroy(event) {
    this.eb.$off(event)
  }
}

export default Event