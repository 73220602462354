export default {
  /**
   * Sets the initialization state of Axios.
   *
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setInitialized(state, payload) {
    state.initialized = payload
  },

  /**
   * Adds a cancel token to the list.
   *
   * @param {Object} state
   * @param {Object} token
   * 
   * @returns {Void}
   */
  addCancelToken(state, token) {
    state.cancelTokens.push(token)
  },

  /**
   * Removes a cancel token from the list.
   *
   * @param {Object} state
   * @param {string} id
   * 
   * @returns {Void}
   */
  removeCancelToken(state, id) {
    let cancelTokens = state.cancelTokens
    let index = cancelTokens.findIndex(token => token.id === id)

    cancelTokens.splice(index, 1)

    state.cancelTokens = cancelTokens
  },

  /**
   * Clears the cancel tokens from the list.
   *
   * @param {Object} state
   * 
   * @returns {Void}
   */
  clearCancelTokens(state) {
    state.cancelTokens = []
  },

  /**
   * Sets the refresh token resolver.
   *
   * @param {Object} state
   * @param {Promise|null} payload
   */
  setRefreshTokenResolver(state, payload) {
    state.refreshTokenResolver = payload
  },

  /**
   * Sets the intended route.
   *
   * @param {Object} state
   * @param {string} payload
   * 
   * @returns {Void}
   */
  setIntendedRoute(state, payload) {
    state.intendedRoute = payload
  }
}