export default {
  /**
   * Sets the available Program Groups.
   * 
   * @param {Object} state 
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setProgramGroups(state, payload) {
    state.programGroups = payload
  }
}