export default {
  id: 'intended-term-of-entry',
  component: 'fr-intended-term-of-entry',
  meta: {
    submitKey: 'termOfEntry',
    default: null,
    label: 'Anticipated Start Term',
    placeholder: '- Select -',
    rules: [],
    classes: ['half-width'],
    visibility: [],
    display: []    
  },
  admin: {
    name: 'Intended Term of Entry',
    mandatory: false,
    required: false,
    required_always: false,
    required_never: false,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}