export default {
    id: 'military-affiliated-checkbox',
    component: 'fr-military-affiliated-checkbox',
    meta: {
      submitKey: 'militaryAffiliated',
      default: false,
      label: null,
      links: [],
      rules: [],
      classes: [],
      visibility: [],
      display: []      
    },
    admin: {
      name: 'Military Affiliated (Checkbox)',
      mandatory: false,
      required: false,
      required_always: false,
      required_never: false,
      dynamic: false
    }
  }