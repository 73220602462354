import { Tooltip } from 'bootstrap'

export const tooltip = {
    mounted(el) {
        const tooltip = new Tooltip(el, {
            boundary: document.body
        })
    },
    unmounted(el) {
        const tooltip = Tooltip.getInstance(el)
        tooltip.dispose()
        
    }
}