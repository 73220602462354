<template>
    <form :id="id" class="form">
        <slot/>
    </form>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            default: ''
        },
    },
}
</script>