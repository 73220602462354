export default {
  id: 'highest-degree-level',
  component: 'fr-highest-degree-level',
  meta: {
    submitKey: 'highestDegree',
    default: null,
    label: 'Highest Level of Education',
    placeholder: 'Highest Level of Education',
    optionsMode: null,
    options: [],
    rules: [],
    classes: [],
    visibility: [],
    display: []    
  },
  admin: {
    name: 'Highest Degree Level',
    mandatory: false,
    required: false,
    required_always: false,
    required_never: false,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}