export default {
  id: 'prospect-type',
  component: 'fr-prospect-type',
  meta: {
    submitKey: 'prospectType',
    default: null,
    label: 'What is your current status?',
    placeholder: '- Select -',
    rules: [],
    classes: [],
    visibility: [],
    display: []    
  },
  admin: {
    name: 'Prospect Type',
    mandatory: false,
    required: false,
    required_always: false,
    required_never: false,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}